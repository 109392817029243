import FooterScreen from "../../_common/FooterScreen/FooterScreen";
import HeaderScreen from "../../_common/Header/HeaderScreen";
import "./TermsCondition.css";
import useTermsCondition from "./useTermsCondition";
import { withTranslation } from "react-i18next";
import { loader } from "../../../assets/images";
import useCountHook from "../../hooks/useCountHook";

const TermsCondition = (props: any) => {
  const { wishListCount } = useCountHook();
  const { t } = props;
  const { privacyData, loaderdata } = useTermsCondition();
  console.log(privacyData?.data?.data?.terms?.description);
  //render header
  const renderHeaderBar = () => {
    return (
      <div>
        <HeaderScreen count={wishListCount} />
      </div>
    );
  };
  /**
   *
   * @returns render main screen
   */

  const renderPrivacy = () => {
    return (
      <div className="container-main">
        <div className="privacyPolicy flt">{t("terms_condition")}</div>
        <div>
          {/* {privacyData && privacyData?.data?.data?.terms?.description &&
                        <p
                            dangerouslySetInnerHTML={{
                                __html: privacyData?.data?.data?.terms?.description,
                            }}
                        />
                    } */}
          <div className="privacy_sub flt"> Shipping Information</div>
          <p>
            Our commitment to ensuring your shopping experience is exceptional
            extends to our shipping policy. For our valued customers within
            Karnataka, we are delighted to offer complimentary shipping on all
            jewelry items, including both gold and silver pieces. In regions
            beyond Karnataka, nominal delivery charges will apply to orders.
            These charges are thoughtfully determined and can be viewed during
            the checkout process, once your location information is entered.
            Typically, the delivery fee is set at ₹500 for Gold products and
            ₹200 for Silver products. At Prakash Jewellers, we prioritize the
            safety and security of your purchase. Our shipping method is not
            only secure but also insured to safeguard your precious items. Upon
            placing an order, please don't hesitate to reach out to us to obtain
            the essential tracking details for your product's journey.
          </p>
          <div className="privacy_sub flt">
            {" "}
            Returns and Exchanges Guidelines
          </div>
          <p>
            Your absolute satisfaction with your purchases from us is of
            paramount importance. Should any item you've ordered from our
            website fall short of your expectations, kindly connect with us
            within six (6) days of your purchase to initiate the return process.
            Our dedicated team will provide you with the necessary return
            authorization and guidelines to facilitate a smooth return or
            exchange. To ensure a successful return or exchange, please ensure
            that the product is returned in its original condition, complete
            with the original packaging. Following a thorough inspection, we
            will swiftly process the refund, crediting the original purchase
            account without delay. It's important to note that specific products
            might have unique return conditions outlined on their respective
            product pages.
          </p>
          <div className="privacy_sub flt"> Gold Jewellery:</div>
          <p>
            We facilitate exchanges and buybacks for gold jewellery exclusively
            in-store, ensuring personalised attention to your needs.
          </p>

          <ul className="termsCondList">
            <li>
              <span className="privacy_li"> Exchange Criteria:</span> Exchange
              is applicable based on the net weight of the ornaments as
              mentioned in the bill, subject to validation for damages.
            </li>
            <li>
              <span className="privacy_li">Exclusion:</span> Making charges and
              taxes are non-reimbursable in exchange transactions.
            </li>
            <li>
              <span className="privacy_li">Prakash Jewellers' Ornaments:</span>{" "}
              Exchange Prakash Jewellers' gold ornaments for cash with a nominal
              deduction of ₹150 per gram.
            </li>
            <li>
              <span className="privacy_li">Bill Copy Requirement:</span> Please
              present the bill copy for any exchange or buyback transactions.
              Exchange or buyback will not be entertained without the bill copy.
            </li>
            <li>
              <span className="privacy_li"> Third-Party Jewellery:</span> We do
              not purchase other brands' gold jewellery for cash.
            </li>
            <li>
              {" "}
              <span className="privacy_li">
                {" "}
                No Bill Copy for Prakash Jewellers' Ornaments:
              </span>{" "}
              If Prakash Jewellers' ornaments are presented without a bill copy,
              we reserve the right to deny the transaction or adjust the rates.
            </li>
            <li>
              <span className="privacy_li">Damage Inspection:</span> All
              jewellery will undergo a damage inspection before processing any
              exchange or buyback.
            </li>
            <li>
              {" "}
              <span className="privacy_li">Non-Buyback Items:</span> Prakash
              Jewellers does not offer buyback for synthetic stones (American
              diamond), beads, and gemstones.
            </li>{" "}
          </ul>
          <div className="privacy_sub flt"> Silver Jewellery/Articles:</div>
          <p>
            {" "}
            Exchange options for silver jewellery and articles are available
            in-store to ensure a seamless experience.
            <li>
              <span className="privacy_li"> Exchange Rate:</span> Exchange rate
              will be determined based on the prevailing rate at our store.
            </li>
            <li>
              <span className="privacy_li"> Refunds:</span> Please note that
              cash refunds are not provided for silver jewellery exchanges.
            </li>
            <div className="privacy_sub flt"> Policy Variation:</div> Prakash
            Jewellers LLP reserves the right to modify the above policies
            without prior notice. Our aim is to provide you with exceptional
            service while adhering to transparent and fair exchange practices.
            For further details or clarification, feel free to visit our store
            or contact us. Please note that the above information is a general
            overview and should be verified with the most current policies at
            the time of your visit.
          </p>
          <div className="privacy_sub flt"> Copyright Notice</div>
          <p>
            We take immense pride in the artistic and intellectual creations
            associated with Prakash Jewellers. All forms of intellectual
            property, including but not limited to images, artwork, literature,
            logos, trademarks, and designs, remain the exclusive property of
            Prakash Jewellers. Our rights are protected by the Copyright Act
            (Amendment) 2012. We kindly request that you respect our ownership
            of these creative assets. Any reproduction, modification,
            distribution, or utilization of material or information found on
            this platform is strictly prohibited without obtaining prior written
            consent from Prakash Jewellers. Any violation of this policy will
            result in the initiation of legal action to protect our rights and
            creations. Your cooperation in this matter is greatly appreciated.
          </p>
        </div>
      </div>
    );
  };

  //loading image display
  // const loadingDisplay = () => {
  //     return (
  //         <div className="product_loader flt">
  //             <img src={loader} alt="populargold" />
  //         </div>
  //     );
  // };

  //render footer
  const renderFooter = () => {
    return (
      <div className="main_login flt">
        <FooterScreen />
      </div>
    );
  };
  return (
    <>
      {renderHeaderBar()}
      {loaderdata !== "isfull"}
      <div className="privacy flt">{renderPrivacy()}</div>
      {renderFooter()}
    </>
  );
};
export default withTranslation()(TermsCondition);
